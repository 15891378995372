//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ctrlCpn } from "@/controller";
import CouponItem from "@/components/Coupon/Item.vue";
import Null from "@/components/Null";
import { Tabs, TabPane } from "element-ui";
export default {
    components: {
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane,
        CouponItem,
        Null,
    },
    data() {
        return {
            activeName: "未使用",
            navList: [
                { name: "未使用", list: [] },
                { name: "已使用", list: [] },
                { name: "已過期", list: [] },
            ],
            couponList: [], //優惠券
            due: [], //已過期優惠券
            unused: [], //未使用優惠券
            used: [], //已使用
            isLoad: true,
        };
    },
    created() {
        this.getCouponList();
    },
    methods: {
        //獲取優惠券數據
        async getCouponList() {
            //未使用 已使用 已過期
            this.couponList = await ctrlCpn.getCouponList();
            [this.navList[0].list, this.navList[1].list, this.navList[2].list] = this.couponList;
            // if (this.Common.isExist(this.couponList)) {
            //     //請求數據完畢，解鎖骨架圖
            //     this.isLoad = false;
            // }
        },
    },
};
